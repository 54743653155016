export const CLASS_TEXTFIELD = "appearance-none w-full rounded-lg border border-gray-300 p-3 focus:outline-none focus:border-gray-400";
export const CLASS_TEXTFIELD_ERROR = "appearance-none w-full rounded-lg border border-red-500 p-3 focus:outline-none focus:border-red-600";

export const CLASS_TEXTFIELD_PL = "pl-8 appearance-none w-full rounded-lg border border-gray-300 py-3 focus:outline-none focus:border-gray-400";
export const CLASS_TEXTFIELD_C = "appearance-none text-center w-full rounded-lg border border-gray-300 p-3 focus:outline-none focus:border-gray-400";
export const CLASS_TEXTFIELD_R = "appearance-none text-right w-full rounded-lg border border-gray-300 p-3 focus:outline-none focus:border-gray-400";
export const CLASS_TEXTFIELD_B0_PL = "pl-8 w-full rounded-lg border-0 py-3 focus:outline-none focus:ring focus:border-green-400";
export const CLASS_ICON_TEXTFIELD_L = "absolute top-1.5 left-2 text-xl text-gray-400 z-10";
export const CLASS_FIELD_LABEL = "text-gray-500 font-bold mb-1";
export const CLASS_FIELD_LABEL_ERROR = "text-red-500 font-bold mb-1";
export const CLASS_FIELD_LABEL_SM = "text-sm text-gray-500 mb-1";
export const CLASS_RADIO_BTN = "";
