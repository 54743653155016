export const routes = {
    dashboard: "/dashboard",
    pasien: "/pasien",
    pasienTambah: "/pasienadd",
    pasienEdit: "/pasienedit",
    antrian: "/antrian",
    loket: "/loket",
    loketTambah: "/loketadd",
    loketEdit: "/loketedit",
    loketDetail: "/loketdetail",
    klinik: "/klinik",
    kasir:"/kasir",
    kasirDetail:"/kasirdetail",
    inventory: "/inventory",
    inventoryItem: "/inventoryitem",
    inventoryRcv: "/inventoryrcv",
    agama: "/agama",
    pegawai: "/pegawai",
    pegawaiDetail: "/pegawaidetail",
    jabatan: "/jabatan",
    kualifikasi: "/kualifikasi",
    kunperiksa:"/kunperiksa",
    kunperiksaTambah: "/kunperiksaadd",
    kunbiaya:"/kunbiaya",
    kamar: "/kamar",
    kamarDetail: "/kamardetail",
    laborat: "/laborat",
    layanan: "/layanan",
    layananDetail: "/layanandetail",
    rujukan: "/rujukan",
    tindakan: "/tindakan",
    tindakanDetail: "/tindakandetail",
    dkstindakanDetail: "/dkstindakandetail",
    pembayaran: "/pembayaran",
    penyakit: "/penyakit",
    penyakitDetail: "/penyakitdetail",
    supplier: "/supplier",
    lokasi: "/lokasi",
    laporan: "/laporan",
    visitorPeriksa: "/visitorperiksa",
    visitorBilling: "/visitorbilling",
    visitorRiwayat: "/visitorriwayat",
}